.info {
    overflow: hidden;
    position: relative;
    background-position: center;
    background-size: cover;
    padding-top: 100px;
    &__title {
        display: grid;
        grid-template-columns: auto 400px;
        column-gap: 40px;
        align-items: end;
        padding-top: 0;
        padding-bottom: 100px;
        margin-top: -300px;
        @include mq($until: 1420px) {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            text-align: center;
        }
        @include mq($until: sm) {
            padding-bottom: 80px;
        }
        @include mq($until: lg) {
            margin-top: 0;
        }
        h1 {
            text-transform: uppercase;
            @include mq($until: 1680px) {
                font-size: rem(30px);
            }

            @include mq($until: md) {
                font-size: rem(32px);
            }
        }
        h2 {
            text-transform: uppercase;
            font-size: rem(52px);
            margin: 0;
            @include mq($until: md) {
                font-size: rem(42px);
            }
            @include mq($until: xs) {
                font-size: rem(40px);
            }
        }
        @include mq($until: lg) {
            .info__image {
                display: none;
            }
        }
        .info__content {
            padding-top: 40px;
            div {
                display: flex;
                align-items: center;
                column-gap: 40px;
                padding-left: 16vw;
                @include mq($until: 1680px) {
                    padding-left: 8vw;
                }
                @include mq($until: 1420px) {
                    padding-left: 0;
                }
                @include mq($until: lg) {
                    justify-content: center;
                }        
                @include mq($until: md) {
                    flex-direction: column;
               }        
            }
            a.button {
                margin-top: 0;
                @include mq($until: md) {
                    margin: 0 auto 20px auto;
                }
            }
        }
        img {
            border-radius: 100%;
            box-shadow: 20px -3px #fff, 20px -3px 20px rgba(0, 0, 0, 0.05);
        }
    }

    &.info2 {
        margin-top: 80px;
        @include mq($until: md) {
            .article{margin-bottom: 0;}
        }
        .article__image {
            @include mq($until: md) {
                margin-bottom: 20px;
            }
            img {
                @include mq($until: md) {
                    left: -10%;
                    right: unset;
                }    
    
                box-shadow: -30px 19px 0 -11px $color-extra, 31px -19px 0 -11px $color-extra;
                right: -16vw;
                left: unset;
            }
        }
    }
    &__content {
        position: relative;
        z-index: 1;
        h3 {
            margin-bottom: 40px;
            text-transform: uppercase;
        }
        .button {
            margin-top: 40px;
        }
    }
    .overlay {
        background-color: $color-body;
    }
    @include mq($until: md) {
        .article__text {
            text-align: center;
            li {
                text-align: left;
                &:before {
                    top: 6px;
                }
            }
        }
    }
    .article__image {
        display: flex;
        align-items: center;
        @include mq($until: md) {
            margin-bottom: 80px;
            height: auto;
        }
        img {
            @include mq($until: md) {
                left: -10%;
                width: 120%;
                height: auto;
                position: relative;
            }    
            border-radius: 100%;
            //height: auto;
            box-shadow: -33px -16px 0 -6px $color-main, 25px 23px 0 -14px $color-main;
            position: absolute;
            left: -16vw;
            width: 126%;
            max-width: unset;
            object-fit: unset;
        }
    }
}
