.leaflet-container {
    height: 80vh;
    .leaflet-marker-icon svg path[fill="#2d3e72"] {
        transition: ease 0.3s all;
    }
    .leaflet-marker-icon svg:hover path[fill="#2d3e72"] {
        fill: $color-main;
    }
    .map__popup {
        &-logo {
            height: 200px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $color-main;
            border-radius: 12px 12px 0 0;
        }
        &-image {
            height: 200px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $color-main;
            background-size: cover;
            border-radius: 12px 12px 0 0;
        }
        &-address {
            font-size: 14px;
        }
        &-content {
            padding: 15px;
        }
        h4 {
            font-size: 18px;
        }
        .button {
            color: #fff;
            display: block;
            margin-top: 20px;
        }
    }
    .leaflet-popup-content {
        margin: 0;
    }

}
