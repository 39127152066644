.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 100px;
  background-color: $color-extra;
  transition: ease 0.3s all;
  z-index: 9;

  &--absolute {
    position: absolute;
    z-index: 3;
    width: 100%;
    border-bottom: none;
    background-color: unset;

    .header__menuLink {
      color: #fff;
      &:hover, &:active, &--active, &--open {
        color: $color-main;
      }
    }
  }

  &--fixed {
    transition: background-color .3s ease-in-out;
    &.sticky {
      position: fixed;
      z-index: 1001;
      width: 100%;
      background-color: $color-extra;
      .header__menuLink {
        color: #fff;
        &:hover, &:active, &--active, &--open {
          color: $color-main;
        }
      }
    }
  }
  &--visible {
    background-color: $color-extra;
    .header__buttons {
      opacity: 0;
    }
  }

  @include mq($until: md) {
    &__right {
      display: flex;
      gap: 15px;
      align-items: center;
    }
  }

  &__buttons {
    display: none;
    @include mq($until: md) {
      display: flex;
      gap: 10px;
    }
    .button {
      padding: 10px;
    }
    svg {
      width: 20px;
      height: 20px;
      fill: #fff;
    }
    &--mobile {
      display: none;
      width: 100%;
      gap: 10px;
      @include mq($until: md) {
        display: flex;
        flex-direction: column;
        order: 1;
      }
      .button {
        width: 100%;
        max-width: 280px;
        display: flex;
        gap: 15px;
        span {
          color: #fff;
        }
      }
      svg {
        width: 20px;
        height: 20px;
        fill: #fff;
      }  
    }
  }

  &__logo{
    position: relative;
    z-index: 2;
    max-width: 300px;
    img {
      height: auto;
      @include mq($until: 1580px) {
        max-width: 200px;
      }
      @include mq($until: lg) {
        width: 13vw;
      }
      @include mq($until: md) {
        width: 140px;
      }
    }

  }


  &__nav {
    display: flex;
    align-items: center;
    column-gap: 30px;
    @include mq($until: 1730px) {
      column-gap: 20px;
    }

    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
      align-items: flex-start
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin: 0;
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: #000;
    padding: 20px 25px;
    display: block;
    font-weight: 300;
    font-size: rem(18px);
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
    @include mq($until: 1730px) {
      padding: 20px 20px;
    }
    @include mq($until: 1660px) {
      padding: 20px 15px;
    }
    @include mq($until: 1380px) {
      font-size: rem(17px);
      padding: 20px 10px;
    }
    @include mq($until: 1280px) {
      font-size: rem(16px);
      letter-spacing: 0px;
    }
    @include mq($until: 1020px) {
      font-size: rem(15px);
    }
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: $color-main;
    }
  }
  @include mq($until: md) {
    .lang-switcher {
      display: flex;
      margin-bottom: 20px;
      .list {
        display: flex!important;
        position: relative;
        margin: 0;
        top: unset;
        background-color: unset;
        li {
          border-bottom: unset;
        }
        a {
          padding: 10px 10px;
        }
      }
    }
  }
}
