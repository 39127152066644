.catalog-map {
    margin-bottom: 100px;
    .map__content {
        height: 80vh;
    }
    .map__popup {
        &-logo {
            height: 200px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $color-main;
            border-radius: 12px 12px 0 0;
        }
        &-image {
            height: 200px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $color-main;
            background-size: cover;
            border-radius: 12px 12px 0 0;
        }
        &-address {
            font-size: 14px;
        }
        &-content {
            padding: 15px;
        }
        h4 {
            font-size: 18px;
        }
        .button {
            color: #fff;
            display: block;
            margin-top: 20px;
        }
    }
    .leaflet-popup-content {
        margin: 0;
    }
}

.catalog-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    @include mq($until: xl){
        gap: 30px;
    }
    @include mq($until: lg){
        .catalog-card__top .catalog-card__price {
            top: 20px;
        }
    }
    &--col2 {
        grid-template-columns: repeat(2, 1fr);
        @include mq($until: xxl){
            &.image-left, &.image-right {
                grid-template-columns: 1fr;
                max-width: 1200px;
                margin: 0 auto;
                .catalog-card.image-left {
                    grid-template-columns: 40% 1fr;
                }
                .catalog-card.image-right {
                    grid-template-columns: 1fr 40%;
                }
            }
        }
        @include mq($until: md){
            &.image-left .catalog-card.image-left, &.image-right .catalog-card.image-right {
                grid-template-columns: 1fr;
            }
            .catalog-card.image-right .catalog-card__top {
                order: 0;
            }            
        }
        @include mq($until: xs){
            grid-template-columns: 1fr;
        }
    }
    &--col3 {
        grid-template-columns: repeat(3, 1fr);
        @include mq($until: md){
            grid-template-columns: repeat(2, 1fr);
        }
        @include mq($until: xs){
            grid-template-columns: 1fr;
        }
    }
    &--col4 {
        grid-template-columns: repeat(4, 1fr);
        @include mq($until: xl){
            grid-template-columns: repeat(3, 1fr);
        }
        @include mq($until: md){
            grid-template-columns: repeat(2, 1fr);
        }
        @include mq($until: xs){
            grid-template-columns: 1fr;
        }
    }
    &.slick-slider {
        display: block;
        .slick-arrow {
            position: absolute;
            top: -70px;
            right: 60px;
            transform: rotate(180deg);
            width: 50px;
            height: 50px;
            padding: 10px;
            border-radius: 100%;
            background: #fff;
            box-shadow: 1px 1px 20px rgba(48, 58, 114, 0.05);
            cursor: pointer;
            transition: ease 0.3s all;
            &:hover {
                background: $color-extra;
                line {
                    stroke: #fff;
                }
            }
            &:last-child {
                right: 0;
                transform: rotate(0);
            }
            line {
                transition: ease 0.3s all;
                stroke: $paragraph-color;
            }
        }
        .slick-track {
            display: flex;
            gap: 30px;
        }
        .slick-slide {
            display: flex;
            height: auto;
        }
        .catalog-card {
            display: flex!important;
            height: 100%;
        }
        .catalog-card.image-left,
        .catalog-card.image-right {
            display: grid!important;
        }        
    }
    &.image-left.slick-slider,
    &.image-right.slick-slider {
        .slick-track {
            gap: 40px;
            @include mq($until: md){
                gap: 0;
            }
        }
        .slick-list {
            //margin-left: -20px;
            //margin-right: -10px;        
            margin-left: -29px;
            margin-right: -19px;
            @include mq($until: md){
                margin-left: 0;
                margin-right: 0;
            }
        }
        .slick-slide {
            display: block;
        }
    }
}
