.features {
    padding-top: 100px;
    padding-bottom: 180px;
    position: relative;
    @include mq($until: md) {
        padding-bottom: 100px;
    }
    &__content {
        position: relative;
        z-index: 1;
        h2 {
            text-align: center;
            text-transform: uppercase;
        }
        p {
            text-align: center;
        }
        .icons-set {
            margin-top: 60px;
            gap: 20px;
            &__item {
                padding: 0;
                row-gap: 0;
                background-color: #fff;
                .image {
                    width: 100%;
                }
                img {
                    border-radius: 10px 10px 0 0;
                    width: 100%;
                }
                &-content {
                    padding: 20px;
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                    text-align: center;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.features2 {
    padding-top: 100px;
    padding-bottom: 100px;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-size: 120%;
    @include mq($until: md) {
        background-size: cover;
    }
    &__content {
        position: relative;
        z-index: 1;
        h2 {
            text-align: center;
            color: #fff;
            font-size: rem(40px);
            text-transform: uppercase;
        }
        p {
            text-align: center;
            color: #fff;
        }
        .icons-set {
            margin-top: 60px;
            max-width: 900px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            &__item {
                align-items: center;
                border: unset;
                img {
                    border-radius: 0;
                    width: 120px;
                }
                h4, span {
                    color: #fff;
                    text-align: center;
                    text-transform: uppercase;
                }
                &:hover img {
                    background: transparent!important;
                }
            }
        }
    }
}

