.subsite {
    .header {
        box-shadow: 1px 1px 20px rgba(48, 58, 114, 0.05);
        div#flags {
            top: 130px;
        }
    }
    .hero {
        min-height: 70vh;
        @include mq($until: md) {
            min-height: 50vh;
        }
        &__reservation {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;        
            position: absolute;
            background-color: $color-extra;
            border-radius: 100%;
            right: 8vw;
            bottom: 0;
            background-size: cover;
            background-position: center;
            padding: 20px;
            width: 340px;
            height: 340px;
            gap: 20px;
            @include mq($until: md) {
                display: none;
            }
            &:hover:after {
                opacity: 0.5;
            }
            &:after{
                content: '';
                background-color: $color-extra;
                width: 100%;
                height: 100%;
                position: absolute;
                border-radius: 100%;
                opacity: 0;
                transition: ease 0.3s all;
            }
            svg {
                width: 140px;
                height: 140px;
                position: relative;
                z-index: 1;
            }
            span {
                position: relative;
                z-index: 1;
                color: #fff;
                font-size: 24px;
                line-height: 24px;
                font-weight: 700;
                max-width: 300px;
                text-align: center;
            }
        }
    }
    &__content {
        padding-top: 0;
        padding-bottom: 100px;
        overflow: hidden;
        @include mq($until: md) {
            padding-top: 0;
            padding-bottom: 100px;
        }
        h1 {
            margin-bottom: 40px;
            @include mq($until: md) {
                padding-top: 40px;
            }    
        }
        h1.title {
            text-align: center;
        }
    }
    .article:not(.reverse) .article__image img {
        border-radius: 100%;
        box-shadow: -33px -16px 0 -6px $color-main, 25px 23px 0 -14px $color-main;
        position: absolute;
        left: -16vw;
        width: 126%;
        max-width: unset;
        -o-object-fit: cover;
        object-fit: cover;
    }
    
    .article.reverse .article__image img {
        border-radius: 100%;
        box-shadow: $color-extra -30px 19px 0px -11px, $color-extra 31px -19px 0px -11px;
        position: absolute;
        right: -16vw;
        width: 126%;
        max-width: unset;
        -o-object-fit: cover;
        object-fit: cover;
    }
    @include mq($until: md) {
        .article.article {
            flex-direction: column;
        }
        .article__text {
            text-align: center;
            ul {
                text-align: left;
            }
        }
        .article .article__image {
            margin-bottom: 80px;
            margin-top: 0;
            height: auto;
            img {
                right: unset!important;
                left: -10%!important;
                width: 120%!important;
                height: auto;
                position: relative!important;
            }
        }
    }
}
