.divider {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 0;
    top: -1px;
    &[data-divider*=top] {
        top: -1px;
        &[data-divider*=gradient]:before {
            content: '';
            width: 100%;
            height: 100px;
            position: absolute;
            background:linear-gradient(0deg, transparent 0%, $color-body 100%);
        }
    }
    &[data-divider*=bottom] {
        top: unset;
        bottom: -1px;
        &[data-divider*=gradient]:before {
            content: '';
            width: 100%;
            height: 100px;
            position: absolute;
            background:linear-gradient(0deg, transparent 0%, $color-body 100%);
        }
    }
    &[data-divider*=rotate] {
        transform: rotate(180deg);
    }
    &[data-divider*=h50] svg {
        height: 50px;
    }
    &[data-divider*=h100] svg {
        height: 100px;
    }
    &[data-divider*=h150]svg {
        height: 150px;
    }
    &[data-divider*=h200] svg {
        height: 200px;
    }
    &[data-divider*=hauto] svg {
        height: auto;
    }
    .fill {
        fill: $color-body;
    }
    .color-main {
        fill: $color-main;
    }
    .color-extra {
        fill: $color-extra;
    }
    .color-body {
        fill: $color-body;
    }
    svg {
        width: 100%;    
    }
}
