.lang-switcher {
  position: relative;
  display: inline-block;
  .selected {
    border-radius: 5px;
    cursor: pointer;
    transition: ease 0.3s all;
    display: flex;
    align-items: center;
    padding: 5px 6px;
    height: 39px;
    position: relative;
    background: rgba(159, 167, 183, 0.2);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);    
    img {
      border-radius: 3px;
    }
    &:hover {
      background-color: $color-main;
    }
  }
  .list {
    display: none;
    position: absolute;
    z-index: 9;
    top: 100%;
    margin: 10px 0 0 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    li {
      list-style: none;
      border-bottom: 1px solid rgba(75, 35, 206, 0.1);
      padding: 0;
      margin: 0;
      &:last-of-type {
        border-bottom: none;
      }
      &.current {
        background-color: rgba(75, 35, 206, 0.05);
      }
      &:before {
        display: none;
      }
    }
    a {
        padding: 5px 10px;
        display: flex;
        align-items: center;
        transition: ease 0.3s background-color;
        &:hover {
          background-color: rgba(75, 35, 206, 0.05);
        }
    }
    img {
      border-radius: 3px;
    }
    &--autowidth {
      width: auto;
    }
    &--toright {
      right: 0;
    }
    &--right {
      top: 0;
      left: 100%;
      margin: 0 0 0 10px;        
    }
    &--left {
      top: 0;
      right: 100%;
      margin: 0 10px 0 0;
    }
    &--above {
      top: unset;
      bottom: 100%;
      margin: 0 0 10px 0;        
    }
  }
  &--opened {
    .selected {
      background-color: $color-main;
      span {
        color: #06122A;
      }
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
