.property {
    &__header {
        display: flex;
        gap: 40px;
        margin-bottom: 80px;
    }
    &__logo {
        display: flex;
        width: 160px;
        height: 160px;
        border-radius: 10px;
        background-color: $color-main;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80%;
        padding: 15px 20px;
    }
    &__title {
        display: flex;
        align-items: center;
    }
    &__informations {
        display: grid;
        grid-template-columns: auto 400px;
        gap: 80px;
        margin-bottom: 80px;
    }
    &__sidebar {
        display: flex;
        flex-direction: column;
        gap: 40px;
        &-widget {
            background-color: #f2f4ff;
            border-radius: 10px;
            padding: 30px;
            h2 {
                font-size: rem(24px);
            }
            .map__content {
                height: 400px!important;
            }
        }
    }
    &__contact {
        div {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 10px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        svg {
            width: 25px;
            fill: $color-main;
        }
    }
    &__photos {
        margin-bottom: 80px;
    }
    &__map {
        margin-bottom: 80px;
        .map__content {
            height: 80vh;
        }
    }
}
