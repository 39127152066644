.apartment {
    &__header {
        display: grid;
        grid-template-columns: 700px minmax(0, 1fr);
        border-top: 100px solid $color-extra;
        @include mq($until: 1400px){
            grid-template-columns: 50% 50%;
        }
        @include mq($until: md){
            grid-template-columns: 100%;
        }
        &-info {
            background-color: $color-extra;
            background-size: cover;
            background-position: center right;
            padding-top: 80px;
            padding-bottom: 80px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            > div {
                position: relative;
                z-index: 1;
            }
            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($color-main,0.9);
            }
            h1 {
                color: #fff;
                font-size: rem(34px);
                font-weight: 700;
                text-transform: uppercase;
            }
            .price {
                color: #fff;
                text-align: right;
                padding-bottom: 30px;
                border-bottom: 1px solid $color-extra;
                font-size: rem(24px);
                @include mq($until: md){
                    text-align: left;
                }
                strong {
                    text-transform: uppercase;
                    font-weight: 700;
                }
            }
            @include mq($until: md){
                .list {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }
            }
            @include mq($until: 700px){
                .list {
                    grid-template-columns: 1fr;
                }
            }

            .list-single {
                display: flex;
                padding: 25px 20px;
                border-radius: 8px;
                transition: ease 0.3s all;
                column-gap: 30px;
                &:hover {
                    background-color: rgba($color-extra, 0.7);
                }
                p {
                    color: #fff;
                    margin-bottom: 0;
                    font-size: rem(15px);
                    strong {
                        font-size: rem(18px);
                    }
                }
                svg {
                    width: 50px;
                    height: auto;
                    fill: #fff;
                }
            }
            .button {
                width: 100%;
                text-transform: uppercase;
                font-size: rem(21px);
                font-weight: 700;
                color: $paragraph-color;
                border-radius: 8px;
                padding: 20px;
                &:hover {
                    color: #fff;
                }
            }
        }
        &-slider {
            position: relative;
            @include mq($until: md){
                aspect-ratio: 4/3;
            }
            .apartment__photo {
                position: absolute;
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: cover;
                top: 0;
                left: 0;
            }
            .slide-photo {
                width: 100%;
                height: 100%;
                background-size: cover;
            }
            .slick-slider, .slick-list, .slick-track {
                height: 100%;
            }
            .slick-slide * {
                font-size: 0;
            }
            .slick-arrow {
                position: absolute;
                left: 150px;
                bottom: 80px;
                border-radius: 100%;
                background: rgba(255, 255, 255, 0.5);
                backdrop-filter: blur(5px);
                transition: ease 0.3s all;
                width: 50px;
                height: 50px;
                padding: 15px;
                z-index: 9;
                stroke: $color-extra;
                @include mq($until: md){
                    left: 110px;
                    bottom: -80px;
                }
                @include mq($until: sm){
                    left: 90px;
                }
                @include mq($until: xs){
                    left: 80px;
                }
                &:first-of-type {
                    transform: rotate(180deg);
                    left: 80px;
                    @include mq($until: md){
                        left: 50px;
                    }    
                    @include mq($until: sm){
                        left: 30px;
                    }    
                    @include mq($until: xs){
                        left: 20px;
                    }    
                }
                &:hover {
                    background: $color-main;
                    stroke: #fff;
                    cursor: pointer;
                }
                line {
                    stroke-width: 2;
                }
            }
        }
    }
    &__content {
        display: grid;
        grid-template-columns: minmax(0, 1fr) 700px;
        @include mq($until: 1400px){
            grid-template-columns: 50% 50%;
        }
        @include mq($until: md){
            grid-template-columns: 100%;
        }
        &-description {
            padding-top: 80px;
            padding-bottom: 80px;
            h2 {
                font-size: rem(25px);
                font-weight: 700;
                margin-bottom: 50px;
            }
            p {
                font-size: rem(16px);
                line-height: rem(32px);
                max-width: 780px;
            }
            a {
                margin-top: 40px;
                display: inline-block;
                color: $paragraph-color;
                opacity: 0.5;
                transition: ease 0.3s all;
                text-decoration: underline;
                font-size: rem(16px);
                font-weight: 700;
                &:hover {
                    opacity: 1;
                }
            }
        }
        &-amenities {
            background-color: #fff;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 20px;
            row-gap: 20px;
            align-content: center;
            padding-top: 80px;
            padding-bottom: 80px;
            @include mq($until: 500px){
                grid-template-columns: 1fr;
            }
            .single {
                display: flex;
                flex-direction: column;
                gap: 20px;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: 10px;
                border-radius: 8px;
                transition: ease 0.3s all;
                @include mq($until: 500px){
                    flex-direction: row;
                    justify-content: flex-start;
                }
                &:hover {
                    background-color: rgba(66, 97, 144, 0.1)
                }
                img, svg {
                    width: 85px;
                    height: auto;
                    @include mq($until: md){
                        width: 60px;
                    }
                    @include mq($until: 500px){
                        width: 40px;
                    }
                }
                p {
                    font-size: rem(15px);
                }
            }
        }
    }
    &__amenities {
        display: grid;
        grid-template-columns: minmax(0, 1fr) 700px;
        min-height: 75vh;
        @include mq($until: 1400px){
            grid-template-columns: 50% 50%;
        }
        @include mq($until: md){
            grid-template-columns: 100%;
        }
        &.full {
            grid-template-columns: 1fr;
            background-color: darken($color-body, 2%);
            .apartment__amenities-about {
                max-width: 1100px;
                margin: 0 auto;
                text-align: center;
            }
            .button {
                width: auto;
            }      
        }
        &-slider {
            position: relative;
            @include mq($until: md){
                aspect-ratio: 4/3;
            }    
            .amenities__photo {
                position: absolute;
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: cover;
                top: 0;
                left: 0;
            }
            .slide-photo {
                width: 100%;
                height: 100%;
                background-size: cover;
            }
            .slick-slider, .slick-list, .slick-track {
                height: 100%;
            }
            .slick-slide * {
                font-size: 0;
            }
            .slick-arrow {
                position: absolute;
                right: 80px;
                bottom: 80px;
                border-radius: 100%;
                background: rgba(255, 255, 255, 0.5);
                backdrop-filter: blur(5px);
                transition: ease 0.3s all;
                width: 50px;
                height: 50px;
                padding: 15px;
                z-index: 9;
                stroke: $color-extra;
                @include mq($until: md){
                    right: unset;
                    left: 110px;
                    bottom: -80px;
                }
                @include mq($until: sm){
                    left: 90px;
                }
                @include mq($until: xs){
                    left: 80px;
                }

                &:first-of-type {
                    transform: rotate(180deg);
                    right: 150px;
                    @include mq($until: md){
                        right: unset;
                        left: 50px;
                    }    
                    @include mq($until: sm){
                        left: 30px;
                    }    
                    @include mq($until: xs){
                        left: 20px;
                    }    
                }
                &:hover {
                    background: $color-main;
                    stroke: #fff;
                    cursor: pointer;
                }
                line {
                    stroke-width: 2;
                }
            }
       }
        &-about {
            padding-top: 80px;
            padding-bottom: 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h2 {
                font-size: rem(25px);
                font-weight: 700;
                margin-bottom: 60px;
            }
            p {
                font-size: rem(15px);
                line-height: rem(32px);
            }
            .button {
                width: 100%;
                text-transform: uppercase;
                font-size: rem(21px);
                font-weight: 700;
                border-radius: 8px;
                padding: 20px;
                margin-top: 40px;
            }
        }
    }
    &__localization {
        display: grid;
        grid-template-columns: 700px minmax(0, 1fr);
        min-height: 60vh;
        @include mq($until: 1400px){
            grid-template-columns: 50% 50%;
        }
        @include mq($until: md){
            grid-template-columns: 100%;
        }
        &-about {
            padding-top: 80px;
            padding-bottom: 80px;
            @include mq($until: md){
                background-color: #fff;
            }

            h2 {
                font-size: rem(24px);
                font-weight: 600;
                margin-bottom: 10px;
            }
            h3 {
                margin-top: 0;
                margin-bottom: 40px;
                font-size: rem(18px);
                font-weight: 400;
            }
            h4 {
                margin-bottom: 20px;
                font-size: rem(16px);
                font-weight: 600;
            }
            p, span, .place {
                font-size: rem(15px);
                line-height: rem(32px);
            }
            .place {
                display: flex;
                justify-content: space-between;
                border-radius: 8px;
                padding: 0 10px;
                transition: ease 0.3s all;
                &:hover {
                    background-color: #fff;
                }
            }
            a {
                margin-top: 40px;
                display: inline-block;
                color: $paragraph-color;
                opacity: 0.5;
                transition: ease 0.3s all;
                text-decoration: underline;
                font-size: rem(16px);
                font-weight: 700;
                &:hover {
                    opacity: 1;
                }
            }
        }
        &-map {
            @include mq($until: md){
                aspect-ratio: 16/9;
            }
            .leaflet-container {
                height: 100%;
            }
        }
    }
    &__faq {
        display: grid;
        grid-template-columns: 700px minmax(0, 1fr);
        @include mq($until: 1400px){
            grid-template-columns: 50% 50%;
        }
        @include mq($until: md){
            grid-template-columns: 100%;
        }
        &-about {
            background-color: #fff;
            padding-top: 80px;
            padding-bottom: 80px;
            h2 {
                font-size: rem(25px);
                font-weight: 700;
                margin-bottom: 50px;
            }
            p {
                font-size: rem(16px);
                line-height: rem(32px);
                max-width: 780px;
            }
            a {
                margin-top: 40px;
                display: inline-block;
                color: $paragraph-color;
                opacity: 0.5;
                transition: ease 0.3s all;
                text-decoration: underline;
                font-size: rem(16px);
                font-weight: 700;
                &:hover {
                    opacity: 1;
                }
            }
        }
        &-questions {
            padding-top: 80px;
            padding-bottom: 80px;
        }
    }
}
